@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600;700&family=Inter:wght@400;500;600&display=swap");

/*
 * Create a new stacking context to ensure that anything inside root
 * that sets a z-index won't overlap the toaster.
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 */
#root {
  position: relative;
  z-index: 0;
}

#root,
html,
body,
#fstrapp {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: 0.15em;
}

div:focus {
  outline: none;
}

body.circles-background,
body.stylishBackground {
  background-image: radial-gradient(
      circle at 69% 86%,
      rgba(165, 165, 165, 0.06) 0%,
      rgba(165, 165, 165, 0.06) 25%,
      rgba(193, 193, 193, 0.06) 25%,
      rgba(193, 193, 193, 0.06) 50%,
      rgba(221, 221, 221, 0.06) 50%,
      rgba(221, 221, 221, 0.06) 75%,
      rgba(249, 249, 249, 0.06) 75%,
      rgba(249, 249, 249, 0.06) 100%
    ),
    radial-gradient(
      circle at 49% 76%,
      rgba(129, 129, 129, 0.06) 0%,
      rgba(129, 129, 129, 0.06) 25%,
      rgba(164, 164, 164, 0.06) 25%,
      rgba(164, 164, 164, 0.06) 50%,
      rgba(200, 200, 200, 0.06) 50%,
      rgba(200, 200, 200, 0.06) 75%,
      rgba(235, 235, 235, 0.06) 75%,
      rgba(235, 235, 235, 0.06) 100%
    ),
    radial-gradient(
      circle at 22% 64%,
      rgba(173, 173, 173, 0.06) 0%,
      rgba(173, 173, 173, 0.06) 25%,
      rgba(119, 119, 119, 0.06) 25%,
      rgba(119, 119, 119, 0.06) 50%,
      rgba(64, 64, 64, 0.06) 50%,
      rgba(64, 64, 64, 0.06) 75%,
      rgba(10, 10, 10, 0.06) 75%,
      rgba(10, 10, 10, 0.06) 100%
    ),
    linear-gradient(307deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

mark {
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0);
}

@keyframes playing {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes infiniteLoadingBar {
  0% {
    background-position-x: 200%;
  }
  50% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 0%;
  }
}

@keyframes delayedHide {
  0%,
  99.9% {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateX(0);
  }
  56% {
    transform: translateX(4px);
  }
}

@keyframes shimmer {
  from {
    backgroundcolor: "#E0E0E0";
  }
  to {
    backgroundcolor: "#F0F0F0";
  }
}

.shopify-webhook-transition-enter {
  background-color: #b3b3fe;
}

.shopify-model-transition-enter {
  background-color: #e2e2e2;
}

[data-docscontent="true"] + [data-docscontent="true"] {
  margin-bottom: 1rem;
}
